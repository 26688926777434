<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Ordenes de compra </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item
        label="Pedidos"
        icon="file-outline"
        :visible="
          permissions.purchase.getRequestOrders === 'S' &&
          companyPaymentPlanModules.purchase.getRequestOrders === 'S'
        "
      >
        <ReqestOrdersList
          :showReception="false"
          :seePrices="true"
        ></ReqestOrdersList>
      </b-tab-item>
      <b-tab-item
        label="Ordenes de compra"
        icon="receipt"
        :visible="
          permissions.purchase.getPurchaseOrders === 'S' &&
          companyPaymentPlanModules.purchase.getPurchaseOrders === 'S'
        "
      >
        <PurchaseOrdersList
          type="all"
          :showReception="false"
          :seePrices="true"
        ></PurchaseOrdersList>
      </b-tab-item>
      <b-tab-item
        label="Artículos faltantes de recepción"
        icon="arrow-down"
        :visible="
          permissions.purchase.getPurchaseOrders === 'S' &&
          companyPaymentPlanModules.purchase.getPurchaseOrders === 'S'
        "
      >
        <BackOrderPurchasesList
          type="all"
          :showReception="false"
        ></BackOrderPurchasesList>
      </b-tab-item>
      <b-tab-item
        label="Artículos en ventas en almacen virtual"
        icon="warehouse"
        :visible="
          permissions.purchase.getPurchaseOrders === 'S' &&
          companyPaymentPlanModules.purchase.getPurchaseOrders === 'S'
        "
      >
        <VirtualBackOrderPurchasesList
          type="all"
          :showReception="false"
        ></VirtualBackOrderPurchasesList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import moment from "moment";
import ReqestOrdersList from "../components/ReqestOrdersList";
import PurchaseOrdersList from "../components/PurchaseOrdersList";
import BackOrderPurchasesList from "../components/BackOrderPurchasesList";
import VirtualBackOrderPurchasesList from "../components/VirtualBackOrderPurchasesList";

// @ is an alias to /src
export default {
  name: "PurchaseOrdersComponentsContainer",
  components: {
    ReqestOrdersList,
    PurchaseOrdersList,
    BackOrderPurchasesList,
    VirtualBackOrderPurchasesList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "purchaseOrders");
  },
  async created() {
    moment.locale("es");
    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETARTICLESPAGINATED", {
        skip: 0,
        limit: 50,
      })
    );
    allPromises.push(
      this.$store.dispatch("GETSALES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(
      this.$store.dispatch("GETPURCHASEORDERS", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(
      this.$store.dispatch("GETREQUESTORDERS", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(this.$store.dispatch("GETLASTPURCHASEORDERNUMBER"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
