var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('b-field',{staticClass:"global-lists-controls",attrs:{"grouped":"","group-multiline":""}}),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1),_c('div',{staticClass:"global-lists-filters-container"})]),_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de artículos faltantes de recepción: "),_c('b',[_vm._v(_vm._s(_vm.articlesTotal))])]),_c('b-table',{ref:"Articles",attrs:{"pagination-position":"both","paginated":"","backend-pagination":"","total":_vm.articlesTotal,"per-page":_vm.perPage,"loading":_vm.loading,"data":_vm.articles,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedArticles,"current-page":_vm.currentPage,"selected":_vm.selected,"opened-detailed":_vm.defaultOpenedDetails,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","checkbox-position":"left","scrollable":"","backend-sorting":"","default-sort-direction":_vm.defaultSortOrder,"default-sort":[_vm.sortField, _vm.sortOrder]},on:{"page-change":function (page) {
        this$1.currentPage = page;
        this$1.reloadInformation();
      },"update:checkedRows":function($event){_vm.checkedArticles=$event},"update:checked-rows":function($event){_vm.checkedArticles=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
          message: ("Información de " + (row.CLAVE_ART)),
          type: 'is-success',
          position: 'is-bottom',
        }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v(" No hay artículos faltantes de recepción ")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label,"custom-sort":column.customSort},scopedSlots:_vm._u([(column.searchable)?{key:"searchable",fn:function(props){return [_c('b-field',[_c('b-input',{on:{"input":function($event){return _vm.reloadInformation()}},model:{value:(_vm.searchFields[props.column.field]),callback:function ($$v) {_vm.$set(_vm.searchFields, props.column.field, $$v)},expression:"searchFields[props.column.field]"}})],1)]}}:null],null,true)},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),(column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(_vm._f("money")(props.row[column.field],"MXN", 2)))]):_vm._e(),(!column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(props.row[column.field]))]):_vm._e()])})}}])},[_c('template',{slot:"detail"},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("No hay información que mostrar")])])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }