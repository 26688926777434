<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="global-lists-filters-container"></div>
    </div>

    <div class="table-header">
      Cantidad de artículos faltantes de recepción: <b>{{ articlesTotal }}</b>
    </div>

    <b-table
      pagination-position="both"
      paginated
      backend-pagination
      :total="articlesTotal"
      :per-page="perPage"
      @page-change="
        (page) => {
          this.currentPage = page;
          this.reloadInformation();
        }
      "
      :loading="loading"
      :data="articles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedArticles"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Articles"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Ordenes de compra de ${row.CLAVE_ART}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      checkbox-position="left"
      scrollable
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
    >
      <template #empty>
        <div class="has-text-centered">
          No hay artículos faltantes de recepción
        </div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          :searchable="column.searchable"
          :sortable="column.sortable"
          v-bind="column"
          :visible="column.display"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <template v-if="column.searchable" #searchable="props">
            <b-field>
              <b-input
                v-model="searchFields[props.column.field]"
                @input="reloadInformation()"
              />
            </b-field>
          </template>
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span v-if="column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field]
          }}</span>
        </b-table-column>

        <b-table-column
          v-for="column in warehousesColumns"
          :searchable="column.searchable"
          :sortable="column.sortable"
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span v-if="checkIfValid(props.row[column.field])">{{
            props.row[column.field]
          }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <b-table
              pagination-position="both"
              :data="props.row.BACK_ORDER_OC"
              :striped="true"
              :bordered="true"
              :hoverable="true"
              ref="PurchaseOrders"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort-direction="desc"
              checkbox-position="left"
              default-sort="CLAVE_PED"
            >
              <template #empty>
                <div class="has-text-centered">No hay ordenes de compra</div>
              </template>

              <template slot-scope="props">
                <b-table-column
                  v-for="column in purchaseOrderColumns"
                  :searchable="column.searchable"
                  :sortable="column.sortable"
                  v-bind="column"
                  :key="column.field"
                  :visible="column.display"
                  :label="column.label"
                  :custom-sort="column.customSort"
                >
                  <span v-if="!checkIfValid(props.row[column.field])">{{
                    "Sin información"
                  }}</span>
                  <span v-if="column.money">{{
                    props.row[column.field] | money("MXN", 2)
                  }}</span>
                  <span v-if="!column.money">{{
                    props.row[column.field]
                  }}</span>
                </b-table-column>
              </template>
            </b-table>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import debounce from "lodash/debounce";
import moment from "moment";
import Masks from "../../../data/cleave-masks";

export default {
  name: "BackOrderPurchasesList",
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedArticles: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      defaultSortOrder: "asc",
      sortField: "CLAVE_ART",
      sortOrder: "asc",
      loading: false,
      searchFields: {
        CLAVE_ART: "",
        DESCRIPCION: "",
      },
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: false,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: false,
          searchable: true,
          display: true,
        },
        {
          field: "lastBuyFormat",
          label: "Fecha de ultima compra",
          sortable: false,
          searchable: false,
          display: true,
        },
        {
          field: "lastSaleFormat",
          label: "Fecha de ultima venta",
          sortable: false,
          searchable: false,
          display: true,
        },
        {
          field: "FALTANTE_TOT",
          label: "Cantidad faltante de recibir",
          sortable: false,
          searchable: false,
          display: true,
        },
        {
          field: "FALTANTE_TOT_CAN",
          label: "Restante de recepción neto",
          sortable: false,
          searchable: false,
          display: true,
          money: true,
        },
      ],
      purchaseOrderColumns: [
        {
          field: "CLAVE_PED",
          label: `${this.type ? "Folio O.C." : "Folio"}`,
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO_CREACION",
          label: "Tipo de creación",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Almacén",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_PRV",
          label: "Proveedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL_GUA",
          label: "Usuario creador",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL_ACT",
          label: "Usuario de ultima edición",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de la orden de compra",
          sortable: false,
          searchable: false,
          display: true,
          customSort: this.sortByDate,
        },
      ],
      warehousesColumns: [],
      masks: Masks,
    };
  },
  mounted() {
    let columnsToAdd = [];
    for (const singleWarehouse of this.warehouses) {
      columnsToAdd.push({
        field: `almacen_${singleWarehouse.NOMBRE_ALM}`,
        label: `${singleWarehouse.NOMBRE_ALM}`,
        sortable: false,
        display: true,
        searchable: false,
      });
    }
    this.warehousesColumns = columnsToAdd;
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    reloadInformation: debounce(async function (search) {
      try {
        this.loading = true;

        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETARTICLESPAGINATED", {
            skip: this.perPage * (this.currentPage - 1),
            limit: this.perPage,
            keySearchInput: this.searchFields.CLAVE_ART,
            descriptionKeyInput: this.searchFields.DESCRIPCION,
          })
        );

        await Promise.all(allPromises);

        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    }, 300),
    formatedDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PED).getTime() || -Infinity) -
          (new Date(a.FECHA_PED).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PED).getTime() || -Infinity) -
          (new Date(b.FECHA_PED).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      let columnsToAdd = [];
      for (const singleWarehouse of this.warehouses) {
        columnsToAdd.push({
          field: `almacen_${singleWarehouse.NOMBRE_ALM}`,
          label: `${singleWarehouse.NOMBRE_ALM}`,
          sortable: true,
          display: true,
          searchable: true,
        });
      }
      this.warehousesColumns = columnsToAdd;
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };

        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        if (singleArticle.FE_ULTCMP) {
          articleWithProps.lastBuyFormat = moment(
            singleArticle.FE_ULTCMP
          ).format("DD-MM-YYYY HH:mm");
        }

        articleWithProps.FALTANTE_TOT = 0;
        articleWithProps.FALTANTE_TOT_CAN = 0;
        articleWithProps.BACK_ORDER_OC = [];

        for (const singleWarehouse of this.warehouses) {
          articleWithProps[`almacen_${singleWarehouse.NOMBRE_ALM}`] = 0;
        }

        for (const singlePurchaseOrder of this.purchaseOrders) {
          let validOc = false;
          if (
            singlePurchaseOrder.STATUS_PED !== "CA" &&
            singlePurchaseOrder.STATUS_PED !== "C"
          ) {
            for (const singleItem of singlePurchaseOrder.PEDIDO_DET) {
              if (
                singleItem.CLAVE_ART &&
                singleItem.CLAVE_ART._id === singleArticle._id
              ) {
                validOc = true;
                articleWithProps[
                  `almacen_${singlePurchaseOrder.CLAVE_ALMACEN.NOMBRE_ALM}`
                ] += singleItem.CANTIDAD_PR;

                if (singleItem.CANTIDAD_PR > 0) {
                  articleWithProps.FALTANTE_TOT += singleItem.CANTIDAD_PR;
                  articleWithProps.FALTANTE_TOT_CAN +=
                    (singleItem.TOTAL / singleItem.CANTIDAD_PE) *
                    singleItem.CANTIDAD_PR;
                }
              }
            }
          }
          if (validOc) {
            let purchaseOrderWithProps = { ...singlePurchaseOrder };
            purchaseOrderWithProps.NOMBRE_ALM =
              singlePurchaseOrder.CLAVE_ALMACEN.NOMBRE_ALM;
            purchaseOrderWithProps.NOMBRE_PRV =
              singlePurchaseOrder.CLAVE_PRV.NOMBRE_PRV;
            purchaseOrderWithProps.formatedCreationDate = moment(
              singlePurchaseOrder.FECHA_PED
            ).format("DD-MM-YYYY HH:mm");
            if (singlePurchaseOrder.STATUS_PED === "P") {
              purchaseOrderWithProps.STATUS_FOR = "Pendiente";
            } else if (singlePurchaseOrder.STATUS_PED === "C") {
              purchaseOrderWithProps.STATUS_FOR = "Completa";
            } else if (singlePurchaseOrder.STATUS_PED === "I") {
              purchaseOrderWithProps.STATUS_FOR = "Incompleta";
            } else if (singlePurchaseOrder.STATUS_PED === "CA") {
              purchaseOrderWithProps.STATUS_FOR = "Cancelada";
            }
            articleWithProps.BACK_ORDER_OC.push(purchaseOrderWithProps);
          }
        }

        return articleWithProps;
      });
    },
    articlesTotal() {
      return this.$store.getters.ARTICLESPAGINATEDTOTAL;
    },
    purchaseOrders() {
      return this.$store.getters.PURCHASEORDERS.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        return purchaseOrderWithProps;
      }).filter((singlePurchaseOrder) => {
        return singlePurchaseOrder.RESTA > 0;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
